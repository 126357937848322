import { useConditionParams } from '@medifind/hooks';
import { InfusionCenterSearch } from '@medifind/pages';
import { usePageHooks } from '@medifind/router';
import { SEO } from '@medifind/seo';
import HostedWidgetWrapper from '../../components/hosted-widgets/HostedWidgetWrapper';
import styles from './entyvio-infusion-center-finder.module.scss';
/* __ROUTE_PATH__=/entyviohcp-infusion-finder */
const EntyvioInfusionCenterFinder = () => {
  usePageHooks();
  useConditionParams();

  return (
    <HostedWidgetWrapper apiKey={'d2cb82f4-b5e0-4979-a157-3fe24af27d15'}>
      <SEO
        title={`Infusion Center Finder | MediFind`}
        description={`Find the best infusion center near you to make sure that you are getting the best care.`}
        canonical={`${process.env.NX_LOCAL_HOST}/entyviohcp-infusion-finder`}
        noIndex={true}
      >
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap" rel="stylesheet" />
      </SEO>

      <InfusionCenterSearch
        rootStyles={{
          '--color': '#9b288c',
          '--accent-color': '#f1eaf0',
          '--font-family-sans-serif':
            'Montserrat, Nunito Sans, Segoe UI, Helvetica Neue, Verdana, -apple-system, blinkmacsystemfont, roboto, arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji',
          '--font-family-serif': 'Montserrat, Mirador, book antiqua, palatino, palatino linotype, palatino lt std, georgia, serif',
          fontFamily: 'var(--font-family-sans-serif)',
        }}
        medication={['entyvio']}
        style={{
          searchButtonContent: { backgroundColor: '#8b2181' },
        }}
        classes={{
          headerHTMLTemplate: styles['header'],
          subHeaderHTMLTemplate: styles['sub-header'],
          termsHeaderHTMLTemplate: styles['terms-header'],
        }}
        countryCodes={['US']}
        poweredByIcon={{
          icon: 'https://cdn.medifind.com/assets/Entyvio_Logo_CMYK.png',
          altText: 'Entyvio',
          width: 200,
        }}
        termsUrl="https://www.medifind.com/terms"
        locationLabel="Infusion center location"
        radiusLabel="How far are you willing to travel?"
        headerHTMLTemplate="Find an ENTYVIO Infusion Center"
        subHeaderHTMLTemplate="Finding the right infusion center is an important step in getting the care you deserve."
        termsHeaderHTMLTemplate="Use this independent infusion center finder to locate infusion centers near you with expertise in your health condition."
        footerHTMLTemplate={`If you are a Colorado prescriber, please see the Colorado WAC <a target="_blank" rel="noopener noreferrer" href="https://assets-dam.takeda.com/image/upload/legacy-dotcom/siteassets/en-us/home/corporate-responsibility/culture-of-compliance/state/CO_Entyvio_Product_Form.pdf">disclosure form.</a><br/><br/>
If you are a Connecticut prescriber, please see the Connecticut WAC <a target="_blank" rel="noopener noreferrer" href="https://assets-dam.takeda.com/image/upload/legacy-dotcom/siteassets/en-us/home/corporate-responsibility/culture-of-compliance/state/ct/CT_Entyvio-Price-Form.pdf">disclosure form.</a><br/><br/>
©2024 Takeda Pharmaceuticals U.S.A., Inc. 500 Kendall Street, Cambridge, MA 02142. 1-877-TAKEDA-7 (1-877-825-3327). All rights reserved. TAKEDA and the TAKEDA logo are registered trademarks of Takeda Pharmaceutical Company Limited. ENTYVIO and the ENTYVIO logo are registered trademarks of Millennium Pharmaceuticals, Inc. All other trademarks are the property of their respective owners. US-VED-1328v3.0&nbsp;10/24`}
        requireTerms={true}
      />
    </HostedWidgetWrapper>
  );
};

export default EntyvioInfusionCenterFinder;
